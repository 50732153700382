.session-my-video-card {
    position: absolute !important;
    width: 194px !important;
    right: 8px;
    bottom: 88px;
}

@media screen and (max-width: 600px) {
    .session-my-video-card {
        width: 128px !important;
        bottom: 152px;
    }
}

.session-container {
    overflow: hidden;
    display: grid;
    grid-template-rows: 1fr 70px;
    background-color: #082B44;
}

.footer-controls {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    height: 50px;
    max-height: 50px;
    justify-content: center;
    align-items: center;
}

.controls-buttons {
    width: 125px;
    display: flex;
    justify-content: space-between;
}

.network-quality-container {
    display: flex;
    align-items: center;
    color: #ffffff;
    margin: 10px;
    align-content: flex-start;
}

.bad {
    background-color: #cc3232!important;
}

.good {
    background-color: #e7b416!important;
}

.excellent {
    background-color: #2dc937!important;
}

.network-audio-container {
    display: flex;
}

.network-video-container {
    display: flex;
}

.network-cycle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ffffff;
    margin: 0 10px;
}

.unapproved-users-container {
    position: absolute;
    top: 0;
    right: 20px;
    width: 250px;
    max-height: 100vh;
    padding: 20px 0;
    z-index: 99;
    overflow-y: auto;
}

.unapproved-users-container::-webkit-scrollbar {
    display: none;
}

.user-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 99%;
    padding: 25px 0;
    margin-bottom: 10px;
    border: 1px solid #CCCCCC11;
    box-shadow: 5px 5px 18px 5px rgba(0, 0, 0, 0.2);
    background-color: #082B44;
}

.session-container video {
    /* background-color: #000000; */
}

.user-face {
    background-image: url(/man-user-dark.svg);
    height: 100px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.username {
    color: #ffffff;
    max-width: 80%;
    margin: 10px 0;
    font-size: 16px;
    font-family: Gilroy-Regular;
    text-align: center;
}

.username span {
    text-decoration: underline;
}

.approved-buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.admit-button {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: url(/success.svg) no-repeat center center;
    background-size: contain;
}

.reject-button {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: url(/error.svg) no-repeat center center;
    background-size: contain;
}

.waiting-container {
    position: absolute;
    bottom: 88px;
    left: 0;
    right: 0;
    margin-top: 16px;
    color: #ffffff;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: center;
    font-family: Gilroy-Regular;
    z-index: 99;
}

@media screen and (max-width: 600px) {
    .waiting-container {
        bottom: 152px;
    }
}

.session-videos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*height: calc(100vh - 50px);*/
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 0 20px 0;
}

.video-spaces {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    /* max-height: 76vh; */
}

.video-containers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.video-containers.p1>.video-container {
    width: 70vw;
    height: 73vh;
    margin: 0;
}

.video-containers.p2>.video-container {
    width: 44vw;
    height: 70vh;
    margin: 10px;
}

.video-containers.p3 {
    margin: 0;
}

.video-containers.p3 {
    /* margin: 0 10%; */
    margin: 0;
}

.video-containers.p3>.video-container {
    width: 35vw;
    height: 42vh;
    margin: 5px;
}

.video-containers.p4 {
    margin: 0;
}

.video-containers.p4>.video-container {
    width: 26vw;
    height: 34vh;
    margin: 10px;
}

.video-containers.p5 {
    margin: 0;
}

.video-containers.p5>.video-container {
    width: 20vw;
    height: 35vh;
    margin: 10px;
}

.video-containers.p6 {
    margin: 0 0;
}

.video-containers.p6>.video-container {
    width: 20vw;
    height: 35vh;
    margin: 10px;
}

.video-containers.p7 {
    margin: 0;
}

.video-containers.p7>.video-container {
    width: 20vw;
    height: 35vh;
    margin: 10px;
}

.video-containers.p8 {
    margin: 0 2%;
}

.video-containers.p8>.video-container {
    width: 20vw;
    height: 35vh;
    margin: 10px;
}

.video-containers.p9 {
    margin: 0 2%;
}

.video-containers.p9>.video-container {
    width: 18vw;
    height: 35vh;
    margin: 10px;
}

.video-containers.p10 {
    margin: 0;
}

.video-containers.p10>.video-container {
    width: 18vw;
    height: 34vh;
    margin: 10px;
}

.video-containers.pu {
    margin: 0 2%;
}

.video-containers.pu>.video-container {
    width: 14vw;
    height: 25vh;
    margin: 10px;
}

.stats-chart-switch {
    position: absolute;
    top: 16px;
    right: 16px;
    color: rgba(255, 255, 255, 0.9);
}