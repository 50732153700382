.chat {
    position: relative;
    z-index: 5;
    height: 100%;
    display: flex;
}

.unread-message {
    position: absolute;
    bottom: -7px;
    right: -5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #bf2117;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.chat-container {
    width: 370px;
    height: calc(100% - 120px);
    max-height: 590px;
    position: fixed;
    right: 16px;
    top: 16px;
    box-sizing: border-box;
    box-shadow: 0 7px 40px 2px rgba(148, 149, 150, 0.3);
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
    border-radius: 4px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    z-index: 99;
    text-align: left;
}

.chat-container.closed {
    opacity: 0;
    visibility: hidden;
    bottom: 90px;
}

.chat-header {
    background: #1976d2;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: white;
    padding: 16px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    position: relative;
    box-sizing: border-box;
    display: flex;
}

.chat-header-room-name {
    align-self: center;
    padding: 10px;
    flex: 1 1;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 5px;
}

.chat-header--close-button {
    width: 40px;
    align-self: center;
    height: 40px;
    margin-right: 10px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 5px;
}

.chat-header--close-button img {
    width: 100%;
    height: 100%;
    padding: 13px;
    box-sizing: border-box;
}

.message-list {
    height: 80%;
    overflow-y: auto;
    background-color: white;
    background-size: 100%;
    padding: 40px 0px;
}

.user-input {
    min-height: 55px;
    margin: 0;
    position: relative;
    bottom: 0;
    display: flex;
    background-color: #f4f7f9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: background-color .2s ease, box-shadow .2s ease;
}

.user-input textarea {
    background-color: transparent;
}

.user-input--text {
    width: 300px;
    resize: none;
    border: none;
    outline: none;
    border-bottom-left-radius: 10px;
    box-sizing: border-box;
    padding: 18px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.33;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #565867;
    -webkit-font-smoothing: antialiased;
    max-height: 200px;
    overflow: scroll;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.user-input--text:empty:before {
    content: attr(placeholder);
    display: block;
    color: rgba(86, 88, 103, .3);
    outline: none;
}

.user-input--buttons {
    width: 100px;
    position: absolute;
    right: 10px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.user-input--button {
    width: 40px;
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.user-input--send-icon-wrapper {
    background: none;
    border: none;
    padding: 2px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chat-container button {
    cursor: pointer;
}

.user-input--send-icon {
    height: 20px;
    width: 20px;
    cursor: pointer;
    align-self: center;
    outline: none;

}

.message {
    width: 300px;
    margin: auto;
    padding-bottom: 10px;
    display: flex;
}

.message-content {
    width: 100%;
    display: flex;
}

.message-content.sent {
    justify-content: flex-end;
}

.message-content.receive {
    justify-content: flex-start;
}

.message-additional-info {
    display: flex;
    font-size: 10px;
    font-style: italic;
    margin-top: 5px;
}

.message-text {
    padding: 17px 20px;
    border-radius: 6px;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    white-space: pre-wrap;
    -webkit-font-smoothing: subpixel-antialiased;
    word-wrap: break-word;
    width: calc(100% - 90px);
    text-transform: initial;
}

.message-content.sent .message-text {
    color: #fff;
    background-color: #4e8cff;
    max-width: calc(100% - 120px);
    word-wrap: break-word;
}

.message-content.receive .message-text {
    color: #263238;
    background-color: #f4f7f9;
    margin-right: 40px;
}
