.timer {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 700;
    margin-right: 8px;
    color: #fff;
    justify-content: flex-end;
    min-width: 60px;
    font-family: 'Courier New', Courier, monospace;
}

@media screen and (max-width: 1000px) {
    .timer {
        display: none;
    }
}