.device-selector-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000088;
  z-index: 103;
  transition: 0.1s ease;
  opacity: 0;
  pointer-events: none;
}

.device-selector-wrapper.opened {
  /* opacity: 1; */
  transition: 0.1s ease;
  /* pointer-events: all; */
}

.ds-content {
  position: absolute;
  /* bottom: 145px;
  right: 280px; */
  top: calc((100vh / 2) - 125px);
  left: calc((100vw / 2) - 250px);
  width: 500px;
  border-radius: 4px;
  background-color: #1976d2;
  box-shadow: 5px 5px 18px 5px rgba(0, 0, 0, 0.1);
  z-index: 105;
  opacity: 0;
  pointer-events: none;
}

.ds-content.opened {
  pointer-events: all;
  opacity: 1;
  transition: 0.1s ease;
}

.ds-content-container {
  height: 100%;
  padding: 20px;
}

.ds-camera-preview {
  width: 100%;
  height: 300px;
  margin: 15px 0 0 0;
  background-color: #000000;
}

@media screen and (max-width: 1000px) {
  .ds-content {
    left: calc((100vw / 2) - 45%);
    width: 90%;
  }
}