.app-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('https://images.squarespace-cdn.com/content/v1/5af0831b85ede1abac791b88/1612983720318-77NA5N7G6MT9KWPQD1TB/Copy+of+behaviour+co-watch+%283%29.jpg');
  background-size: 100% auto;
}

.app-bg::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.69);
}

@media screen and (max-width: 599px) {
  .app-bg::after {
    background-color: rgba(0, 0, 0, 0.87);
  }
}

@media screen and (max-width: 900px) {
  .app-bg {
    background-size: auto 100%;
  }
}

.app-bg-dark {
  /* background-color: #082b44; */
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.powered-by-container {
  grid-column-start: 1;
  grid-column-end: -1;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0)); */
}

.powered-by-text {
  color: #ffffff;
  font-size: 16px;
  font-family: Gilroy-Regular;
}

.logo-top {
  background-image: url(/sceenic.svg);
  background-repeat: no-repeat;
  width: 90px;
  height: 110px;
  background-size: contain;
  background-position: center;
}

.hide {
  display: none !important;
}
