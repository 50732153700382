.login-card {
    position: relative;
    height: calc(100% - 50px);
    width: 380px;
    display: flex;
    justify-content: row;
    margin: 0 auto;
}

.login-form {
    display: flex;
    margin: auto;
    flex-direction: column;
}

.login-form-item {
    margin: 8px 0 !important;
}

.login-form-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
}

.login-form-btn {
    width: calc(50% - 8px);
}
