.video-quality {
    position: absolute;
    left: 0;
    top: calc((100vh / 2) - 125px);
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.closed {
    opacity: 0;
    visibility: hidden;
}

.video-quality-container {
    width: 400px;
    padding: 20px 10px;
    background-color: #1976d2;
    color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    justify-content: center;
    transition: .3s ease-in-out;
    z-index: 101;
}

.video-width-container {
    display: flex;
    font-size: 13px;
    margin: 0 10px;
    justify-content: space-between;
    align-items: center;
}

.slider-container {
    width: 60%;
}

.MuiSlider-root {
    color: #FFFFFF!important;
}

.MuiSlider-mark, .MuiSlider-markActive {
    color: transparent!important;
    background-color: transparent!important;
}

.video-quality-footer-buttons {
    display: flex;
    justify-content: space-around;
    margin: 15px 0 0 0;
}

.video-quality-footer-buttons button {
    border: none;
    height: 40px;
    width: 100px;
    color: #ffffff;
    margin: 0;
    padding: 0 10px;
    border-radius: 48px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF22;
}

.video-quality-footer-buttons button:hover {
    background-color: rgba(63, 81, 181, 0.4);
}

.video-quality-footer-buttons button:focus {
    outline: none;
    border: none;
}

@media screen and (max-width: 1000px) {
    .video-quality-container {
        width: 90%;
        height: auto;
    }
}