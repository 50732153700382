.preview-login-wrapper {
  min-width: 320px;
  max-width: 40%;
  align-self: center;
}

.preview-controls-wrapper {
  margin: auto;
  margin-top: 20px;
}

.preview-controls-wrapper .camera-control {
  margin: 8px 0;
}

.preview-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.preview-header {
  margin: 0 0 60px 0;
  font-size: 18px;
  font-family: Gilroy-Regular;
}

.preview-content {
  display: flex;
  width: 1200px;
}

.preview-camera-block {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  margin: 0 10px;
  /* height: 100%; */
  /* max-height: 440px;
  min-height: 360px; */
}

.p-camera {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #000;
  color: #FFFFFF;
}

.p-camera-placeholder {
  position: absolute;
  font-size: 16px;
  font-family: Gilroy-Light;
}

.p-block {
  width: 100%;
  height: 100%;
}

.p-camera-controls {
  position: absolute;
  left: 20px;
  bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  border-radius: 5px;
}

.p-actions {
  display: flex;
  justify-content: flex-end;
  width: 1200px;
  margin: 20px;
}

.p-action-button {
  font-size: 16px;
  font-family: Gilroy-Regular;
}

.camera-preview-icon {
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(/camera-white.svg);
}

.menu-preview-icon {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  z-index: 15;
  cursor: pointer;
  background-image: url(/dots.svg);
  background-size: contain;
}

.preview-main-settings-block {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  padding: 20px 30px;
  border-radius: 5px;
  background-color: #09263C;
}

.p-ms-title {
  margin: 20px 0;
  font-size: 16px;
  font-family: Gilroy-Regular;
}

.p-ms-title>span {
  font-family: Gilroy-Bold;
}

.p-ms-title>span.enabled {
  color: #20AD1D;
}

.p-ms-title>span.disabled {
  color: #E24235;
}

.p-ms-title>span.waiting {
  color: #FBB00E;
}

@media screen and (max-width: 1000px) {
  .preview-container {
    overflow-y: auto;
    padding: 350px 0 200px 0;
  }
  .preview-content {
    flex-direction: column;
    width: 300px;
    height: auto;
  }
  .preview-camera-block {
    margin: 0;
    min-width: 100%;
  }
  .preview-main-settings-block {
    width: 100%;
    margin: 20px 0;
  }
  .p-actions {
    width: auto;
  }
}