.chart-wrapper {
    position: absolute;
    top: -6px;
    left: 0;
    right: 0;
    background-color: rgba(246, 246, 246);
    padding: 8px;
    border-radius: 8px;
    padding-top: 4px;
}

.chart-select-abel {
    background-color: rgba(246, 246, 246);
}