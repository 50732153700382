.camera-controls-title>span {
    font-family: Gilroy-Bold;
}

.camera-controls-title>span.enabled {
    color: #20AD1D;
}

.camera-controls-title>span.disabled {
    color: #E24235;
}

.camera-controls-title>span.waiting {
    color: #FBB00E;
}

.camera-controls-fast {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
}

.camera-controls-fast button {
    margin: 0 8px;
}
