.footer-left {
    position: absolute;
    display: flex;
    bottom: 16px;
}

.footer-center {
    margin: auto;
    display: flex;
    margin-top: 16px;
}

.footer-right {
    position: absolute;
    display: flex;
    bottom: 16px;
}

.footer-fab-leave:hover {
    background-color: #FF6861 !important;
}

.footer-control-menu-container {
    position: absolute;
    width: 200px;
    height: auto;
    bottom: 55px;
    right: 33px;
    background-color: #071b29;
    z-index: 20;
    border-radius: 5px;
    visibility: hidden;
}

.footer-control-menu-container:hover {
    visibility: visible;
}
