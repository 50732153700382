.m-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000088;
  z-index: 100;
  transition: 0.1s ease;
  opacity: 0;
  pointer-events: none;
}

.m-wrapper.opened {
  opacity: 1;
  transition: 0.1s ease;
  pointer-events: all;
}

.m-content {
  position: absolute;
  bottom: 90px;
  right: 20px;
  width: 250px;
  /* height: 500px; */
  border-radius: 4px;
  background-color: #1976d2;
  box-shadow: 5px 5px 18px 5px rgba(0, 0, 0, 0.1);
  z-index: 101;
  opacity: 0;
  pointer-events: none;
}

.m-content.opened {
  pointer-events: all;
  opacity: 1;
  transition: 0.1s ease;
}

.m-list-icon {
  width: 20px;
  height: 20px;
  margin: 0 15px 0 0;
  background-position: center;
  background-size: contain;
}

.m-list-icon.player {
  background-image: url(/video-player.svg);
}

.m-list-icon.headphones {
  background-image: url(/headphones.svg);
}